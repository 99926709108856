@import 'styles';
.root {
  :global(.rc-dialog-body) {
    padding: 40px 30px 30px;
    font-size: 18px;
  }
}

.footer {
  text-align: right;
  &._center {
    text-align: center;
  }
  margin-top: 40px;
  button {
    min-width: 105px;
  }
  button + button {
    margin-left: 10px;
  }

  &.default {
    button {
      min-width: 125px;
    }
  }
}
