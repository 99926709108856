@use 'styles/themes';

.r-select__multi-value--is-disabled {
  > .r-select__multi-value__label {
    padding-right: 6px;
  }
  > .r-select__multi-value__remove {
    display: none;
  }
}

.dark {
  .r-select__single-value {
    color: themes.$color-background-hi;
  }
}
