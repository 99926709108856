@import 'styles';
.wrapClassName {
  z-index: $dialog-z-index + 3;
}
.content {
  text-align: center;
  font-size: 18px;
  i {
    color: $color-green;
    font-size: 36px;
    margin-bottom: 26px;
  }
}
.handSoftphone {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
}
