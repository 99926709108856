//@import 'styles/sass/mixins';
@import 'styles';

.gutter {
  @include media-breakpoint-all-ranges() {
    @include row-gutter();

    &_off {
      @include row-gutter-off();
    }
  }
}

.wrap {
  @include media-breakpoint-all-ranges() {
    @include row-wrap();

    &_off {
      @include row-nowrap();
    }
  }
}

.alignItems {
  @include media-breakpoint-all-ranges() {
    @include row-align-items-make();
  }
}

.justifyItems {
  @include media-breakpoint-all-ranges() {
    @include row-justify-items-make();
  }
}

.justifyContent {
  @include media-breakpoint-all-ranges() {
    @include row-justify-content-make();
  }
}
