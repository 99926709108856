@use 'styles/constants/colors';
@use 'styles/fn';
@use 'styles/themes';

.root {
  display: flex;

  &.row {
    flex-wrap: wrap;

    .item {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
  &.column {
    flex-direction: column;

    .item {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.button {
  padding: 0;
  border: none;
  background: none;
  line-height: 1;

  color: #828282;
  font-size: 14px;
  font-weight: normal;
  display: inline-flex;
  align-items: center;

  &.active {
    color: themes.$color-main;
  }

  // REFACT: :has
  &._withDesc {
    display: inline-grid;
    grid-gap: 2px 10px;
    grid-template-columns: min-content fn.zfr(1);
    grid-template-rows: auto auto;
    grid-template-areas:
      'point label'
      '...   desc';
    align-items: center;
    text-align: left;

    > .point {
      grid-area: point;
      margin: 0;
    }
    > .label {
      grid-area: label;
      font-size: 16px;
      font-weight: 600;
      color: colors.$color-line-dark;
    }
    > .desc {
      grid-area: desc;
      color: colors.$color-grey;
      font-size: 12px;
    }
  }

  &:disabled {
    color: themes.$color-disabled;

    > .label {
      color: colors.$color-grey;
    }
  }
}

.point {
  // REFACT: CSS vars

  border-radius: 50%;
  background-color: currentColor;
  margin-right: 4px;

  .button._withDesc > &,
  .root._bigPoint & {
    width: 20px;
    height: 20px;
  }
  .root._bigPoint .button:not(._withDesc) > & {
    margin-right: 10px;
  }
  &,
  .root._defaultPoint & {
    width: 8px;
    height: 8px;
  }
}

.group {
  &.active {
  }

  > .button {
  }
  > .children {
    margin: 10px 20px 20px;

    .root._bigPoint & {
      margin-left: 30px;
    }
  }
}
