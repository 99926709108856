.root {
  display: flex;
  flex-wrap: wrap;

  margin: -5px;
}
.colorItem {
  position: relative;
  width: calc(20% - 10px);
  height: 64px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    color: #fff;
    font-size: 32px;
  }

  &:hover {
    transform: scale(1.05);
  }

  .clear {
    position: absolute;
    top: 0;
    right: 0;
  }
}
