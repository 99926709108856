@import 'styles';

.root {
  position: relative;
  display: inline-flex;
  align-items: center;

  .input {
    position: absolute;
    //height: 32px;
    left: 0;
    width: 36px;
    opacity: 0;
    @include default-transition();
  }

  .btn {
    position: absolute;
    left: 0;
    @include default-transition();
  }

  &.focused {
    .input {
      width: 100%;
      opacity: 1;
    }

    .btn {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.expandLeft {
    .btn,
    .input {
      right: 0;
      left: unset;
    }
  }
}
