.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;

  // TODO: color: бледный;

  &._100 {
    width: 100%;
    height: 100%;
  }

  .image {
  }

  .text {
  }

  // ага, а для `flex-direction` не изобрели `margin-{axis}`, лодыри
  &._vert {
    flex-direction: column;
    > * ~ * {
      margin-top: 20px;
    }
  }
  &._horz {
    flex-direction: row;
    > * ~ * {
      margin-left: 20px;
    }
  }
}
