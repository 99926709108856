.tags {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.tag {
  color: #ffffff;
  font-weight: 500;
  max-width: 80px;
  padding: 2px 8px;
  border-radius: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 14px;

  &:not(:first-child) {
    margin-left: 4px;
  }
}

.tagsQty {
  font-weight: 700;
  margin-left: 4px;
}
