.root {
  width: 35px;
  height: 35px;
  min-height: 0;
  border-radius: 50%;
  border-style: dashed;
  border-color: currentColor !important;
  background-color: transparent;
  padding: 0;
  justify-content: center;
  //color: #bdbdbd;
}
