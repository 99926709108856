@use '../../constants/colors';
@use '../../themes';
@use './config' as c;

%input-box {
  --input-b-width: #{c.$border};
  --input-b-radius: #{c.$radius};
  --input-pad-x: #{c.$pad-x};
  --input-pad-y: #{c.$pad-y};
  --input-font: #{c.$font};
  --input-lh-abs: #{c.$lh};

  --input-height: #{c.$default-height};

  height: var(--input-height);
  background: themes.$input-def-bg;
  color: themes.$input-def-tx;
  border: #{themes.$input-def-br} solid var(--input-b-width);
  border-radius: var(--input-b-radius);
  font-size: var(--input-font);
  line-height: var(--input-lh-abs);
}

%input-disabled {
  border-color: themes.$input-dis-br;
  background-color: themes.$input-dis-bg;
  color: themes.$input-dis-tx;
}

%input-padding-x {
  padding-left: var(--input-pad-x);
  padding-right: var(--input-pad-x);
}
%input-padding-y {
  padding-top: var(--input-pad-y);
  padding-bottom: var(--input-pad-y);
}
%input-padding {
  padding: var(--input-pad-y) var(--input-pad-x);
}

%input-size-small {
  --input-height: #{c.$small-height};
}

%input-only-text {
  border: none;
  padding: 0;
  outline: none;
  width: auto;
  height: auto;
  min-width: 0;
  min-height: 0;
  background: transparent;
}
