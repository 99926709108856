@use 'sass:color';
@use 'styles/themes';

.root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.fullScreen {
    height: 100vh;
  }

  &.cover {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &.compact {
    height: 60px;
  }

  &.include {
    position: absolute;
  }

  &.relCover {
    position: absolute;
    inset: 0;
  }
  &.relTop {
    position: absolute;
    inset: 0 0 auto 0;
  }

  &.tint::before {
    content: ' ';
    display: block;
    position: absolute;
    inset: 0;
    background: themes.$color-background;
    opacity: 0.5;
  }
}
