.popupTop,
.popupNested {
  --popup-max-width: 400px;
  width: max-content;
  max-width: min(var(--popup-max-width), calc(100vw - 20px));

  max-height: calc(100vh - 20px);
  overflow-y: auto;

  > button,
  > .item {
    min-width: 100%;
    text-align: inherit;
    line-height: 1.3;
    height: auto;
    min-height: 30px;
  }
}

.popupTop {
  //outline: red dashed 2px;
}

.popupNested {
  //border: blue dotted 4px;
}
