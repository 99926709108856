@import '../constants/grid';
@import 'breakpoints';

@mixin col() {
  margin-left: var(--grid-gutter-pad, 0);
  margin-right: var(--grid-gutter-pad, 0);
  flex-grow: 1;
  flex-basis: 0;
  max-width: calc(100% - 2 * var(--grid-gutter-pad, 0));
}

@mixin col-span($span) {
  $outer-width: calc($span / $grid-cols) * 100%;
  $width: calc(#{$outer-width} - 2 * var(--grid-gutter-pad, 0));

  // this property with native css lise, calc and var cause SASS to crash silently?
  //flex: 0 0 #{$width};
  // so, it is expanded:
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: $width;

  max-width: $width;
}

@mixin col-span-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@mixin row() {
  padding: 0;

  display: flex;
  flex-wrap: nowrap;
}

@mixin row-gutter($gutters: $grid-gutters) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint) {
      $pad: calc($gutter / 2);
      --grid-gutter-pad: #{$pad};
      margin-left: calc(var(--grid-gutter-pad, 0) * -1);
      margin-right: calc(var(--grid-gutter-pad, 0) * -1);
    }
  }
}

@mixin row-gutter-off() {
  --grid-gutter-pad: 0px;
  margin-left: 0;
  margin-right: 0;
}

@mixin row-cols-span($span) {
  > * {
    @include col-span($span);
  }
}
@mixin row-cols-span-auto() {
  > * {
    @include col-span-auto();
  }
}
@mixin row-cols-span-make() {
  @for $span from 1 through $grid-cols {
    &_#{$span} {
      @include row-cols-span($span);
    }
  }

  &_auto {
    @include row-cols-span-auto();
  }
}
@mixin col-span-make() {
  @for $span from 1 through $grid-cols {
    &_#{$span} {
      @include col-span($span);
    }
  }

  &_auto {
    @include col-span-auto();
  }
}

@mixin row-wrap() {
  flex-wrap: wrap;
}
@mixin row-nowrap() {
  flex-wrap: nowrap;
}

@mixin row-align-items-make() {
  &_top {
    align-items: flex-start;
  }
  &_bottom {
    align-items: flex-end;
  }
  &_center {
    align-items: center;
  }
  &_stretch {
    align-items: stretch;
  }
  &_baseline {
    align-items: baseline;
  }
}

@mixin row-justify-items-make() {
  &_start {
    justify-items: flex-start;
  }
  &_end {
    justify-items: flex-end;
  }
  &_center {
    justify-items: center;
  }
  &_stretch {
    justify-items: stretch;
  }
}

@mixin row-justify-content-make() {
  &_start {
    justify-content: flex-start;
  }
  &_end {
    justify-content: flex-end;
  }
  &_center {
    justify-content: center;
  }
  &_stretch {
    justify-content: stretch;
  }
  &_space-between {
    justify-content: space-between;
  }
  &_space-around {
    justify-content: space-around;
  }
  &_space-evenly {
    justify-content: space-evenly;
  }
}

@mixin col-align-self-make() {
  &_top {
    align-self: flex-start;
  }
  &_bottom {
    align-self: flex-end;
  }
  &_center {
    align-self: center;
  }
  &_stretch {
    align-self: stretch;
  }
  &_baseline {
    align-self: baseline;
  }
}

@mixin col-justify-self-make() {
  &_start {
    justify-self: flex-start;
  }
  &_end {
    justify-self: flex-end;
  }
  &_center {
    justify-self: center;
  }
  &_stretch {
    justify-self: stretch;
  }
}
