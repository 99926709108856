@import 'styles';

//examples
//https://doodlenerd.com/html-control/css-checkbox-generator
//https://codepen.io/wtaype/pen/pNXwMW
.control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 1em;
  width: 1em;
  font-size: 20px;
  background-color: transparent;
  flex-shrink: 0;

  --check-off-bg: #e6e6e6;
  --check-off-bg-active: #cccccc;
  --check-on-bg: #{$color-main};
  --check-on-fc: #fff;
  --check-disabled-bg: #e6e6e6;
  --check-disabled-fc: #7b7b7b;

  input[type='checkbox'] {
    position: absolute;
    cursor: inherit;
    height: 100%;
    width: 100%;
    inset: 0;
    z-index: 5;
    opacity: 0;
  }

  input:disabled {
    cursor: auto;
  }
}
.indicator {
  position: absolute;
  inset: 0;
  background: var(--check-off-bg);
  //border: 0 solid #000000;
  border-radius: 4px;
}
.indicatorAuto {
}
.indicatorIcon {
}
.control:hover input ~ .indicator,
.control input:focus ~ .indicator {
  background: var(--check-off-bg-active);
}

.control input:checked ~ .indicator {
  background: var(--check-on-bg);
  color: var(--check-on-fc);
  //background: inherit;
}
.control:hover input:not([disabled]):checked ~ .indicator,
.control input:checked:focus ~ .indicator {
  //background: #0e6647d;
}
.control input:disabled ~ .indicator {
  background: var(--check-disabled-bg);
  color: var(--check-disabled-fc);
  opacity: 0.6;
  pointer-events: none;
}
.indicatorAuto::after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .indicatorAuto::after {
  display: block;
}
.control .indicatorAuto::after {
  left: calc(8 / 20 * 100%);
  top: calc(4 / 20 * 100%);
  width: calc(3 / 20 * 100%);
  height: calc(8 / 20 * 100%);
  border: solid currentColor;
  $b: calc(2 / 20 * 1em);
  border-width: 0 $b $b 0;
  transform: rotate(45deg);
  z-index: 3;
}
.control input:disabled ~ .indicatorAuto::after {
  //border-color: #7b7b7b;
}

.control input:checked + .indicator::before {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background-color: inherit;
  content: '';
  display: block;
  position: absolute;
  z-index: 1;

  width: 1em;
  height: 1em;
  transform: translate(calc(-50% + 0.5em), calc(-50% + 0.5em));
  border-radius: 50%;
  opacity: 0;
}

@keyframes click-wave {
  0% {
    width: 1em;
    height: 1em;
    opacity: 0.35;
  }
  100% {
    width: 2.5em;
    height: 2.5em;
    opacity: 0;
  }
}
