@import 'styles';
.root {
  height: 100%;
  position: relative;

  &.isDrag {
    .cover {
      opacity: 0.9;
      z-index: 2;
      visibility: visible;
    }
  }
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $color-excretion;
  border: 2px dashed;
  z-index: 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  padding: 18px;
  text-align: center;

  @include default-transition();
  opacity: 0;
  // Ясно, что opacity был сделан для анимации при драге, но просто голый
  // opacity мешает обычному взаимодействию с нормальным контентом блока.
  visibility: hidden;
}
