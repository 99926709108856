@import 'styles';
$size: 250px;

.root {
  width: $size;
  height: $size;
  cursor: pointer;

  background: url('/assets/img/logoUpload.svg') center/cover no-repeat;

  @include default-transition();

  &:hover {
    transform: scale(1.03);
  }
}
