@use '../themes';

// #331 эксперименты
%themed-scrollbars {
  scrollbar-color: themes.$scroll-thumb themes.$scroll-track;

  // TODO: Safari
  // https://caniuse.com/css-scrollbar
  // https://webkit.org/blog/363/styling-scrollbars/
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    background: themes.$scroll-track;
  }
  &::-webkit-scrollbar-thumb {
    background: themes.$scroll-thumb;
  }
}
