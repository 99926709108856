@use 'styles/themes';

.wrapper {
  transition: opacity 0.15s ease-in;
}
.popover {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;
  background: themes.$input-def-bg !important;
  max-width: 450px;
  white-space: pre-wrap;
  word-break: break-word;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
