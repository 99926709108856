@import 'styles';

.root {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: #ffffff;
  line-height: 1;

  // #289
  // показал Сергею скрины нескольких вариантов, по которым и был сделан выбор
  //
  //text-shadow: 0 0 4px colors.$color-background-dark;
  //text-shadow: 0 0 1px colors.$color-background-dark;
  //text-shadow: 0 0 6px colors.$color-background-dark;
  //text-shadow: 0 0 4px #000;
  text-shadow: 0 0 1px #000;
  //text-shadow: 0 0 2px #000;
  //text-shadow: 0 0 6px #000;

  border-radius: 4px;
  max-width: 230px;

  white-space: nowrap;

  padding: 0 8px;
  height: 30px;

  cursor: pointer;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  .btnDelete {
    height: auto;
    min-height: auto;
    margin-top: 0;
    margin-right: -8px;
  }

  &._disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.root {
  &.isHoverForDelete {
    position: relative;

    .btnDelete {
      position: absolute;
      background: $color-grey;
      padding: 0;
      right: 8px;
      opacity: 0;
      @include default-transition(opacity);
    }

    &:hover {
      .btnDelete {
        opacity: 1;
      }
    }
  }
}
