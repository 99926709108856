.root {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  // нативная кнопка [Выберите файл] не имеет желаемый курсор
  // на и пошла отсюда тогда
  padding: 0 0 0 100%;
  border: 0;
  overflow: clip;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}
