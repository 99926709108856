$layout-header-height: 151px;
$layout-header-height-sm: 78px;

$layout-left-width: 312px;
$layout-left-width-md: 273px;

$layout-content-padding-y: 13px;
$layout-content-padding-y-sm: 7px;
$layout-content-padding-x: 17px;
$layout-content-padding-x-sm: 6px;

$dialog-border-radius: 6px;
$dialog-z-index: 1050;

$chat-padding-x: 30px;
$chat-padding-x-sm: 10px;

$field-margin-bottom: 25px;

$input-border-radius: 10px;
