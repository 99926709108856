@import 'styles';
:root .container {
  z-index: 99;
  max-width: none;
  padding: 13px;
  margin: -13px;
}
.panel {
  padding: 12px;
  display: flex;
  text-align: left;
}
.col {
  flex: 1 0 auto;
  padding: 12px;
  &:not(:last-child) {
    border-right: 1px solid $color-divider;
  }
}
.btnRange {
  display: block;
  white-space: nowrap;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btns {
  white-space: nowrap;
  text-align: right;
  button {
    min-width: 105px;
    margin-left: 12px;
  }
}
