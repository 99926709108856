@import 'styles';
.root {
  color: $color-background;
  background-color: $color-accent;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  min-width: 22px;
  border-radius: 50%;
  text-align: center;
}
