@use 'styles/constants/colors';

.root {
  display: inline-flex;
  align-items: center;
  border: transparent solid 1px;
  border-radius: 5px;

  .text {
    margin: 0 0.25em;
  }
  // REFACT: :has()
  &._hasText {
    &:hover {
      border-color: colors.$color-grey;
    }
  }
}
