@use 'styles/components';
@use 'styles/components/button/config';

.root {
  @extend %button;
}

._default {
  @extend %button-default;
}
._primary {
  @extend %button-primary;
}
._danger {
  @extend %button-danger;
}
._success {
  @extend %button-success;
}
._accent {
  @extend %button-accent;
}
._secondary {
  @extend %button-secondary;
}

._sizeDef {
  @extend %button-size-default;
  min-width: config.$default-width;
}
._sizeSmall {
  @extend %button-size-small;
  min-width: config.$small-width;
}
._sizeLarge {
  @extend %button-size-large;
  min-width: config.$large-width;
}
._sizeLargeX {
  @extend %button-size-largeX;
  min-width: config.$largeX-width;
}
