@use 'styles/fn';
@use 'styles/mixins';
@use 'styles/themes';

.trigger {
  display: inline-block;
  &._rotateIcon {
    i {
      @include mixins.default-transition();
    }

    &._open {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

.popupRootFitViewport {
  > .popup {
    max-height: calc(100vh - 20px);
    overflow-y: auto;
  }
}

.popup {
  background-color: themes.$color-background;
  color: themes.$color-background-inv;
  //padding: 15px 0;
  --popup-max-width: 400px;
  max-width: min(var(--popup-max-width), calc(100vw - 20px));

  > .header {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  &._hasIcons {
  }

  button.item {
    min-width: 100%;
    text-align: inherit;
  }
}

.item {
  padding: 8px 12px;
  height: auto;
  min-height: 30px;

  $icon-size: 24px;
  $arrow-size: 18px;

  display: grid;
  grid-gap: 0 10px;
  grid-template-columns: fn.zfr(1);
  grid-template-rows: min-content min-content;
  grid-template-areas:
    'label'
    'hint';
  &._hasChildren {
    grid-template-columns: fn.zfr(1) $arrow-size;
    grid-template-areas:
      'label arrow'
      'hint  hint';

    &._childrenOpen {
      background: themes.$color-excretion;
    }
  }
  .popup._hasIcons > & {
    grid-template-columns: $icon-size fn.zfr(1);
    grid-template-areas:
      'icon label'
      '.... hint';
    align-items: center;
    &._hasChildren {
      grid-template-columns: $icon-size fn.zfr(1) $arrow-size;
      grid-template-areas:
        'icon label arrow'
        '.... hint  hint';
    }
  }

  > .icon {
    font-size: $icon-size;
    grid-area: icon;
  }

  > .label {
    grid-area: label;
  }

  > .arrow {
    grid-area: arrow;
    transform: rotate(-90deg);
  }

  > .hint {
    grid-area: hint;
    margin-top: 5px;
    font-size: 10px;
    line-height: 1.4;
  }
}

.divider {
  margin: 6px 0;
}
