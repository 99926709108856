@use 'sass:math';
@import 'styles';

$gap: 4px;
.root {
  margin: math.div(-$gap, 2);
}
.options {
}
.button {
  width: 25px;
  height: 25px;
  font-size: 14px;
  margin: math.div($gap, 2);

  background: #828282;
  color: #ffffff;

  &.selected {
    background: $color-main;
  }

  &:hover {
    transform: scale(1.05);
  }
}
