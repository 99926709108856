@use 'styles/constants/colors';
@use 'styles/mixins';
@use 'styles/themes';

.root {
  //width: 100%;
}
.container {
  overflow: hidden;
  position: relative;
}
.more {
  position: absolute;
  inset: 0 0 0 auto;

  &._notCut {
    visibility: hidden;
  }

  > * {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .moreTrigger {
    width: auto;
    min-width: 0;
    aspect-ratio: 1;
    --btn-pad-x: 0;
  }
}

.tabs {
  display: flex;
  color: #97a5c9;
  font-size: 16px;
  line-height: 28px;
  white-space: nowrap;
  position: relative;
}
.tab {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
  @include mixins.default-transition();

  &:not(:last-child) {
    margin-right: 24px;
  }

  .icon {
    // Это изменение размера где-то уже мешало. Надо подумать.
    // - Рассылки - Создать - Вкладки контента vs иконки итого
    font-size: 1.5em;
    &._withText {
      margin-right: 5px;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      height: 1em;
      width: auto;
    }
  }
  .qty {
    display: inline-block;
    margin-left: 0.25em;
  }
}

a.tab {
  font-weight: unset;
  font-size: unset;
  line-height: unset;
  color: inherit;
  text-decoration: none;
}

.tab {
  &:before {
    content: '';
    height: 3px;
    position: absolute;
    inset: auto 0 0 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    opacity: 0;
    @include mixins.default-transition();
  }
  &:hover:before {
    background-color: colors.$color-divider;
    opacity: 1;
  }

  &.active {
    color: colors.$color-main;

    &:before {
      opacity: 1;
      background-color: colors.$color-main;
    }
  }
}

.tab {
  &.withBadge {
    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 2px;
      right: -5px;
      background-color: colors.$color-red;
    }
  }

  &.dragover {
    position: relative;
    margin-left: 10px;

    .dragPlaceholder {
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: colors.$color-main;
      border-radius: 4px;
      top: 0;
      left: -10px;
      opacity: 0.5;
    }
  }

  &.dragover.dragRight {
    margin-left: 0;
    margin-right: 15px;

    .dragPlaceholder {
      left: unset;
      right: -10px;
    }
  }

  &.dragover.dragRight {
    margin-left: 0;
    margin-right: 15px !important;
  }
}
