//@import 'styles/sass/mixins';
@import 'styles';

// resolve rules order manually

// example layout:
//
//   div.row.colsSpan_2
//     div.col.span_3     // this must have span 3 from own props
//     div.col            // this must have span 2 from row props
//
// To let this to work:
//
// *   Col's `.col`  must be BEFORE Row's `.colsSpan`,
// *   Col's `.span` must be AFTER  Row's `.colsSpan`.

// row general
.row {
  @include row();
}

// col general
.col {
  @include col();
}

// row default col span
.colsSpan {
  @include media-breakpoint-all-ranges() {
    @include row-cols-span-make();
  }
}

// row own span
.span {
  @include media-breakpoint-all-ranges() {
    @include col-span-make();
  }
}
