@use 'styles/constants/colors';
@use 'styles/themes';

.root {
  input {
    height: auto;
  }
}

.affix {
  font-size: 24px;
  margin-left: 8px;
  color: themes.$input-def-tx;
  display: inline-flex;
}

.moreItems {
  font-style: italic;
  white-space: nowrap;
  color: colors.$color-grey;
}

.valueWrapper {
  pointer-events: all;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
