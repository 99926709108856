@use 'styles/constants/colors';
@use 'styles/themes';

// REFACT: если subgrid когда-то вообще выйдет, то хз, можно ли будет на него
//   порефакторить, чтобы и перенос формулы работал

.root {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
  align-items: flex-start;
  justify-content: flex-start;
}

.var {
  flex: 0 0 auto;
  margin-top: 10px;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    '. title   .'
    'b content a'
    '. help    .';
  grid-template-columns: auto min-content auto;
  grid-template-rows: auto auto auto;
  align-items: center;
}
.before {
  grid-area: b;
}
.title {
  grid-area: title;
  align-self: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: themes.$color-main;
  white-space: nowrap;
  text-align: center;
}
.content {
  grid-area: content;
}
.help {
  grid-area: help;
  align-self: flex-start;
  margin-top: 5px;
  font-size: 10px;
  color: colors.$color-grey;
}
.after {
  grid-area: a;
}

.before,
.after {
  padding-left: 7px;
  padding-right: 7px;
  font-size: 20px;
}

.title,
.content,
.help {
  margin-left: 2px;
  margin-right: 2px;
}
