@use 'styles/components';
@use 'styles/mixins';

.dialog2 {
  @extend %fix-dark-dialog;
}

.dialog {
  :global(.rc-dialog-body) {
    padding: 83px 10px 87px;
  }
  :global(.rc-dialog-close) {
    z-index: 99;
  }

  &:global(.rc-dialog) {
    //@media (min-width: 700px) {
    //  width: auto;
    //}
    //@media (max-width: 819.9998px) {
    //  margin: 10px;
    //}
    //@media (min-width: 820px) {
    //  width: 800px;
    //}
    &._large,
    &._full {
      width: auto;
      margin: 10px;
    }

    &._large {
      @media (min-width: 1024px) {
        width: 960px;
        margin: 30px auto;
      }
    }
    &._full {
    }
  }
}

@include mixins.media-breakpoint-down(xs) {
  .dialog {
    :global(.rc-dialog-body) {
      padding: 32px 10px 48px;
    }
  }
}
