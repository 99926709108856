@use 'sass:color';
@use 'styles/constants/colors';
@use 'styles/themes';
@use './config';

@mixin button-variant(
  $bg,
  $border,
  $color,
  $hover-bg,
  $hover-border,
  $hover-color,
  $active-bg,
  $active-border,
  $active-color,
  $disabled-bg: $bg,
  $disabled-border: $border,
  $disabled-color: $color,
  $loading-bg,
  $focus-color
) {
  --btn-bg: #{$bg};
  --btn-color: #{$color};
  --btn-border-color: #{$border};
  --btn-hover-bg: #{$hover-bg};
  --btn-hover-color: #{$hover-color};
  --btn-hover-border-color: #{$hover-border};
  --btn-active-bg: #{$active-bg};
  --btn-active-color: #{$active-color};
  --btn-active-border-color: #{$active-border};
  --btn-disabled-bg: #{$disabled-bg};
  --btn-disabled-color: #{$disabled-color};
  --btn-disabled-border-color: #{$disabled-border};
  --btn-loading-bg: #{$loading-bg};
  --btn-focus-color: #{$focus-color};
}

@mixin button-size(
  $pad-x,
  $pad-y,
  $font-size,
  $font-weight,
  $line-height-abs,
  $radius: config.$radius,
  $icon-size: $font-size * 1.5,
  $icon-space: $font-size * 0.5,
  $min-height:
    calc(
      /*var(--btn-font-size) * */ var(--btn-line-height) + 2 * var(--btn-pad-y) + 2 *
        var(--btn-border-width)
    )
) {
  --btn-pad-x: #{$pad-x};
  --btn-pad-y: #{$pad-y};
  --btn-font-size: #{$font-size};
  --btn-font-weight: #{$font-weight};
  --btn-line-height: #{$line-height-abs};
  --btn-height: #{$min-height};
  --btn-radius: #{$radius};
  --btn-icon-size: #{$icon-size};
  --btn-icon-space: #{$icon-space};
}

%button {
  --btn-color: #{themes.$color-title};
  --btn-bg: transparent;
  --btn-border-width: #{config.$border};
  --btn-border-color: transparent;
  --btn-disabled-opacity: #{config.$disabled-opacity};
  --btn-focus-box-shadow: 0 0 0 #{config.$focus-width} var(--btn-focus-color);

  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: var(--btn-pad-y) var(--btn-pad-x);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  min-height: var(--btn-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-radius);
  background: var(--btn-bg);
  transition: config.$btn-transition;
  touch-action: manipulation;

  &:hover {
    color: var(--btn-hover-color);
    text-decoration: none;
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
  }

  &:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow);
  }

  &:active,
  &:global(._active) {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color);

    &:focus {
      box-shadow: var(--btn-focus-box-shadow);
    }
  }

  &:disabled,
  &:global(._disabled) {
    color: var(--btn-disabled-color);
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    pointer-events: none;
    opacity: var(--btn-disabled-opacity);
    box-shadow: none;
  }
}

%button-size-default {
  @include button-size(
    $pad-x: config.$pad-x,
    $pad-y: config.$pad-y,
    $font-size: config.$font,
    $font-weight: config.$font-weight,
    $line-height-abs: config.$lh,
    $icon-size: config.$default-icon,
    $icon-space: config.$default-icon-space,
    $min-height: config.$default-height
  );
}
%button-size-small {
  @include button-size(
    $pad-x: config.$pad-x,
    $pad-y: config.$pad-y,
    $font-size: config.$small-font,
    $font-weight: config.$small-font-weight,
    $line-height-abs: config.$small-lh,
    $icon-size: config.$small-icon,
    $icon-space: config.$small-icon-space,
    $min-height: config.$small-height
  );
}
%button-size-large {
  @include button-size(
    $pad-x: config.$pad-x,
    $pad-y: config.$pad-y,
    $font-size: config.$large-font,
    $font-weight: config.$large-font-weight,
    $line-height-abs: config.$large-lh,
    $icon-size: config.$large-icon,
    $icon-space: config.$large-icon-space,
    $min-height: config.$large-height
  );
}
%button-size-largeX {
  @include button-size(
    $pad-x: config.$pad-x,
    $pad-y: config.$pad-y,
    $font-size: config.$large-font,
    $font-weight: config.$largeX-font-weight,
    $line-height-abs: config.$largeX-lh,
    $icon-size: config.$largeX-icon,
    $icon-space: config.$largeX-icon-space,
    $min-height: config.$largeX-height
  );
}

%button-default {
  @include button-variant(
    $bg: colors.$color-main,
    $border: colors.$color-main,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-main-hover,
    $hover-border: colors.$color-main-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-main-active,
    $active-border: colors.$color-main-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-disabled,
    $disabled-border: colors.$color-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-main-focus
  );
}
%button-primary {
  @include button-variant(
    $bg: colors.$color-main,
    $border: colors.$color-main,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-main-hover,
    $hover-border: colors.$color-main-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-main-active,
    $active-border: colors.$color-main-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-main-disabled,
    $disabled-border: colors.$color-main-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-main-focus
  );
}
%button-danger {
  @include button-variant(
    $bg: colors.$color-danger,
    $border: colors.$color-danger,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-danger-hover,
    $hover-border: colors.$color-danger-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-danger-active,
    $active-border: colors.$color-danger-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-danger-disabled,
    $disabled-border: colors.$color-danger-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-danger-focus
  );
}
%button-success {
  @include button-variant(
    $bg: colors.$color-success,
    $border: colors.$color-success,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-success-hover,
    $hover-border: colors.$color-success-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-success-active,
    $active-border: colors.$color-success-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-success-disabled,
    $disabled-border: colors.$color-success-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-success-focus
  );
}
%button-accent {
  @include button-variant(
    $bg: colors.$color-accent,
    $border: colors.$color-accent,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-accent-hover,
    $hover-border: colors.$color-accent-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-accent-active,
    $active-border: colors.$color-accent-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-accent-disabled,
    $disabled-border: colors.$color-accent-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-accent-focus
  );
}
%button-secondary {
  @include button-variant(
    $bg: colors.$color-secondary,
    $border: colors.$color-secondary,
    $color: colors.$color-main-dark,
    $hover-bg: colors.$color-secondary-hover,
    $hover-border: colors.$color-secondary-hover,
    $hover-color: colors.$color-main-dark,
    $active-bg: colors.$color-secondary-active,
    $active-border: colors.$color-secondary-active,
    $active-color: colors.$color-main-dark,
    $disabled-bg: colors.$color-secondary-disabled,
    $disabled-border: colors.$color-secondary-disabled,
    $disabled-color: colors.$color-main-dark,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-secondary-focus
  );
}

// он тут последний, чтобы суметь всё переопределить,
// т.к. `@extend` приписывает остальные селекторы к целевому
%button-text {
  @include button-variant(
    $bg: transparent,
    $border: transparent,
    $color: inherit,
    $hover-bg: transparent,
    $hover-border: transparent,
    $hover-color: colors.$color-main-hover,
    $active-bg: transparent,
    $active-border: transparent,
    $active-color: colors.$color-main-active,
    $disabled-bg: transparent,
    $disabled-border: transparent,
    $disabled-color: colors.$color-disabled,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-main-focus
  );
  --btn-font-weight: normal;
}
