a.root,
.root.withIcon {
  display: inline-flex;
}
.root {
  &.withIcon {
    i {
      font-size: var(--btn-icon-size);
    }
    i + span,
    span + i {
      margin-left: var(--btn-icon-space);
    }
  }
  &._loading {
    // REFACT: так не положено же вложение активных элементов друг в друга
    input[type='file'] {
      visibility: hidden;
    }

    &::before,
    > .loaderCover {
      position: absolute;
      inset: 0;
      //inset: calc(0 - var(--btn-border-width));
    }
    &::before {
      content: ' ';
      display: block;
      background: var(--btn-loading-bg);
      opacity: 0.75;
      margin: calc(0px - var(--btn-border-width));
      border-radius: inherit;
    }
    > .loaderCover {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .icon {
  }
  .text {
  }
}
