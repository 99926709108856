.root {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  color: #ffffff;
  line-height: 28px;
  text-align: center;

  &.useShadow {
    box-shadow: 0 1px 13px rgba(82, 54, 255, 0.3);
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    text-align: center;
  }
}
