@use 'styles/mixins/mixins';

.root {
  > .head {
  }

  > .body {
  }

  &._expanded {
    //> .head {
    //}
    > .body {
      display: block;
    }
  }

  &._collapsed {
    > .head {
      .hToggle i {
        transform: rotate(180deg);
      }
    }

    > .body {
      display: none;
    }
  }
}

.head {
  display: flex;
  align-items: center;
  align-content: center;

  > *:not(:first-child) {
    margin-left: 10px;
  }
}

.hTitle {
  flex: 1 1 0;

  @at-root button#{&} {
    display: block;
    text-align: left;
    padding: 0.5em 1em;
  }
}

.hStart,
.hToggle,
.hEnd {
  flex: 0 0 auto;
}

.hToggle i {
  @include mixins.default-transition(transform);
}
