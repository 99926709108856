@use 'styles/themes';
@import 'styles';

.root,
.root2 {
  text-align: center;
  width: 786px;
}
.content {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  > * {
    width: 100%;
  }
}
.title {
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
  color: $color-background-dark;
  .root2 & {
    color: themes.$color-title;
  }
  margin: 50px 0;
}

.subtitle {
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 50px;
}

.children {
  font-size: 18px;
  line-height: 24px;
  color: $color-grey;
  margin-bottom: 33px;
}

.endHelp {
  margin-top: 30px;
  color: $color-grey;
  font-size: 12px;
}

.link {
  margin-bottom: 62px;
  display: block;
}

.icon {
  > i {
    font-size: 150px;
  }
}
.img {
  width: 183px;
  height: 183px;
}

@include media-breakpoint-down(sm) {
  .root {
    width: calc(100% - 20px);
  }
}

@include media-breakpoint-down(xs) {
  .root {
    width: calc(100% - 20px);
    .img {
      width: 125px;
      height: 125px;
    }
  }

  .content {
    max-width: 310px;
  }
  .title {
    font-size: 30px;
    margin-bottom: 23px;
  }
  .subtitle {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 21px;
  }
  .children {
    font-size: 16px;
  }
  .link {
    font-size: 16px;
    margin-bottom: 56px;
  }
  .btn {
    --btn-height: 49px;
    --btn-font-size: 18px;
    border-radius: 15px;
    --btn-pad-x: 19px;
  }
}
