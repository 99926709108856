@use 'styles/constants/colors';
@use 'styles/constants/layout';

:root {
  .ct-row {
    z-index: layout.$dialog-z-index + 1;
  }

  .ct-toast {
    min-height: 62px;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    //font-size: 16px;
    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #ffffff !important;
      }
    }
  }

  .ct-text {
    font-weight: 600;
    font-size: 15px;
  }

  .ct-toast-success {
    background-color: #1abc9c;
    border-left: 5px solid #108b73 !important;
  }

  .ct-toast-error {
    background-color: colors.$color-danger;
    border-left: 5px solid colors.$color-danger-active !important;
  }

  // цвета ниже были выбраны грубо из палитры

  .ct-toast-warn {
    background-color: #d7cd7e;
    border-left: 5px solid #918016 !important;
  }

  .ct-toast-info {
    background-color: #6bc6e7;
    border-left: 5px solid #1c87b6 !important;
  }

  .ct-toast-loading {
    background-color: #73a1fd;
    border-left: 5px solid #2458a6 !important;
  }
}
