.wrap {
  display: flex;
}

.root {
  // Пригодно только для мелкого диалога. Большой диалог будет обрезан, когда
  // не влезает, и похер ему на скролл. Возможно, это можно как-то доработать,
  // но сейчас нет необходимости, и мысль сама по себе не приходит.
  align-self: center;

  :global(.rc-dialog-body) {
    padding: 40px;
    display: flex;
    justify-content: center;
  }

  .content {
    max-width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;

    > * {
      margin: 0 10px;
    }
  }

  .icon {
    font-size: 4em;
  }
  .message {
  }
  .loader {
    font-size: 2em;
  }
}
