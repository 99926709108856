@use 'styles/constants/colors';
@use 'styles/constants/layout';
@use 'styles/themes';

.root {
  .button {
    padding: 0;
    height: auto;
    position: relative;
    i {
      font-size: 34px;
      line-height: 34px;
      font-style: normal;
      transform: none !important;
      color: colors.$color-disabled;
    }
    .count {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 4px;
      font-size: 12px;
      line-height: 16px;
      min-width: 16px;
      border-radius: 20px;
      background: themes.$color-main;
      color: themes.$color-main-inv;
    }

    &._hasEvents {
      i {
        color: colors.$color-red;
      }
    }
  }
}

.popupWrapper {
  z-index: #{layout.$dialog-z-index + 3};
}

:root .popup {
  padding: 5px;
  border: colors.$color-grey solid 1px;
  border-radius: 5px;
  background: colors.$color-excretion;
  min-width: 400px;

  > :only-child {
    margin: 0;
  }
}
