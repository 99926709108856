@use 'styles/fn';
@use 'styles/constants/colors';
@use 'styles/themes';

$btn-plus-size-half: calc(var(--tree-view-btn-plus-size) / 2);

.root {
  position: relative;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: var(--tree-view-btn-plus-size) fn.zfr(1);
  align-items: center;

  grid-template-areas: 'btn title';

  &._maybeChildren {
    grid-template-rows: auto fn.zfr(1);
    grid-template-areas:
      'btn title'
      '... children';
  }
  &._sureNoChildren {
    grid-row-gap: 0;
    grid-template-rows: auto;
  }

  &._currentDirect,
  &._currentParent {
    > .title {
      color: themes.$color-main;
    }
  }
  &._currentDirect {
  }
  &._currentParent {
  }

  > :nth-child(1) {
    grid-column: 1;
    grid-row: 1;
    grid-row-end: span 2;
    display: block;
    position: relative;
    align-self: stretch;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      inset: 0 auto 0 50%;
      border: colors.$color-grey dotted 0;
      border-left-width: 1px;
    }
  }
  &:last-child > :nth-child(1) {
    grid-row-end: span 1;
    &::before {
      inset: 0 0 50% 50%;
      border-bottom-width: 1px;
    }
  }

  > :nth-child(2) {
    grid-area: btn;
  }
  > :nth-child(3) {
    grid-area: title;
  }
  > :nth-child(4) {
    grid-area: children;
  }
}

.btnStub {
  display: inline-block;
}
.button {
  justify-content: center;
  height: var(--tree-view-btn-plus-size);
  min-height: 0;
  padding: 0;
  background: themes.$color-background !important;
  color: themes.$color-button-icon;
  border: currentColor solid var(--tree-view-btn-plus-border) !important;
  border-radius: 0;

  i {
    --btn-icon-size: calc(var(--tree-view-btn-plus-size) - 2 * var(--tree-view-btn-plus-border));
    line-height: var(--btn-icon-size);
  }
}

.title {
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-start;
  text-align: left;
  border-radius: 0;
}

.children {
  margin-left: 5px;
  padding-bottom: 5px;
}
