@use 'styles/constants/colors';
@use 'styles/components';
@use 'styles/components/button/config';

.root {
  @extend %button, %button-text;
  vertical-align: initial;

  &.withIcon {
  }
}
a.root:not(.withIcon) {
  min-height: 0;
}

._default {
  @extend %button-default;
}
._primary {
  @extend %button-primary;
  --btn-color: #{colors.$color-main};
  --btn-hover-color: #{colors.$color-main-hover};
  --btn-active-color: #{colors.$color-main-active};
  --btn-disabled-color: #{colors.$color-main-disabled};
  --btn-focus-color: #{colors.$color-main-focus};
}
._danger {
  @extend %button-danger;
  --btn-color: #{colors.$color-danger};
  --btn-hover-color: #{colors.$color-danger-hover};
  --btn-active-color: #{colors.$color-danger-active};
  --btn-disabled-color: #{colors.$color-danger-disabled};
  --btn-focus-color: #{colors.$color-danger-focus};
}
._success {
  @extend %button-success;
  --btn-color: #{colors.$color-success};
  --btn-hover-color: #{colors.$color-success-hover};
  --btn-active-color: #{colors.$color-success-active};
  --btn-disabled-color: #{colors.$color-success-disabled};
  --btn-focus-color: #{colors.$color-success-focus};
}
._accent {
  @extend %button-accent;
  --btn-color: #{colors.$color-accent};
  --btn-hover-color: #{colors.$color-accent-hover};
  --btn-active-color: #{colors.$color-accent-active};
  --btn-disabled-color: #{colors.$color-accent-disabled};
  --btn-focus-color: #{colors.$color-accent-focus};
}
._secondary {
  @extend %button-secondary;
  --btn-color: #{colors.$color-secondary};
  --btn-hover-color: #{colors.$color-secondary-hover};
  --btn-active-color: #{colors.$color-secondary-active};
  --btn-disabled-color: #{colors.$color-secondary-disabled};
  --btn-focus-color: #{colors.$color-secondary-focus};
}

._sizeDef {
  @extend %button-size-default;
  --btn-pad-x: 6px;
}
._sizeSmall {
  @extend %button-size-small;
  --btn-pad-x: 6px;
  --btn-icon-size: 24px;
  --btn-font-size: 16px;
  --btn-line-height: 18px;
}
._sizeLarge {
  @extend %button-size-large;
  --btn-pad-x: 6px;
}
._sizeLargeX {
  @extend %button-size-largeX;
  --btn-pad-x: 6px;
}
