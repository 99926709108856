@import 'styles';
.container {
  z-index: 9;
}
.root {
  cursor: pointer;
  white-space: nowrap;
  max-width: 100%;
  display: inline-flex;
  align-items: center;

  span {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &.open {
    .ico {
      transform: rotate(-180deg);
    }
  }
}

.ico {
  margin-left: 7px;
  color: #b9c0de;
  //todo разобраться с багом мерцания шрифтов на странице во время анимации. Воспроизводится в хроме, возможно где еще (в фф нет)
  // пробовал костыли ниже, но не помогает
  //will-change: transform;
  //-webkit-transform: translate3d(0,0,0);

  @include default-transition(transform);
}
