@use 'styles/constants/colors';
@use 'styles/themes';

button.root {
  background: transparent;
}
.root {
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  line-height: 30px;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  height: 30px;

  &:disabled {
    color: themes.$color-disabled;
  }
  &:hover:not(:disabled) {
    background-color: themes.$color-background-hover;
  }

  &.useChecked:not(.checked) {
    padding-left: 38px;
  }
}

.ico,
.checkIco {
  margin-right: 6px;
  font-size: 20px;
}
.checkIco {
  color: colors.$color-green;
}
