.makeExpand {
  // Чтобы контейнер можно было развернуть в отладчике заранее, когда он ещё пустой.
  // А то есть тут некоторые хитрожопые select'ы, которые по-другому хрен застанешь
  // врасплох, т.к. они сразу сваливают, когда теряют фокус.
  &::before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    clip: rect(0, 0, 0, 0);
  }
}
