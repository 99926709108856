@import 'styles';

.root {
  display: flex;
}

$size: 30px;
.status {
  width: $size;
  height: $size;
  background-color: $color-text-dark;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    width: $size * 0.8;
    height: $size * 0.8;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(.active):after {
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 0.3;
    }
  }
}

.status {
  &.open {
    &:after {
      background-color: $color-task-opened;
    }
  }
  &.expired {
    &:after {
      background-color: $color-task-expired;
    }
  }
  &.closed {
    &:after {
      background-color: $color-task-closed;
    }
  }
}
