@import 'styles';

.root {
  position: relative;
  width: 100%;
  text-align: left;

  .control {
    font-size: initial;
    color: initial;
    font-weight: initial;
    flex: auto;
    input,
    select,
    textarea {
      margin-bottom: 0;
    }
  }

  .label {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: $color-grey;
    .control {
      margin-top: 9px;
    }

    &.labelLeft {
      display: flex;
      align-items: center;
      .control {
        margin-top: 0;
        margin-left: 9px;
      }
    }
    &.notInput {
      display: block;
      // FIXME: мешает, когда `.labelLeft`
      margin-bottom: 9px;
    }
  }
}

.requiredMark {
  color: $color-red;
}

.helper,
.error {
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
.error {
  margin-bottom: 9px;
  color: $color-red;

  &:empty {
    &::before {
      content: ' ';
      display: inline-block;
      white-space: pre;
    }
  }
}
.helper {
}

.withHelpIcon {
  display: flex;
  align-items: center;
}

.helpIcon {
  color: $color-grey;
  margin-left: 8px;
  font-size: 20px;
  flex-shrink: 0;
  cursor: help;
}

.root {
  &.isError {
    .label {
      color: $color-red;
    }
    .control {
      > *:not(button):not(a) {
        // REFACT: поменять на `border-color`, чтобы внезапный `border` не
        //   появлялся там, где он и не был задуман
        border: 1px solid $color-red;
      }
    }
    //input {
    //  border: 1px solid $color-red;
    //}
  }
}
