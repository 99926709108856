@use 'styles/themes';

.root {
  text-align: center;
  font-size: 12px;
  color: themes.$color-title;
  padding: 20px;

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.img {
  width: 160px;
}
.title {
  font-weight: 700;
  margin-top: 20px;
}
.message {
  font-weight: 500;
  margin-top: 20px;
  line-height: 24px;
}
