.toolbox {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  > button {
    font-size: 12px;
    line-height: 1.2;
    height: auto;
  }
}

.customer {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
