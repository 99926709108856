@import 'styles';
.root {
  //border: 1px solid;
  //text-align: center;
  display: flex;
}

.barWrapper {
  flex: 1;
  text-align: center;
}
.completedTitle {
  margin-bottom: 6px;
  color: $color-grey;
  font-weight: 700;
  font-size: 14px;
}
.bar {
  flex: 1;
  height: 8px;
  border-radius: 4px;
  background-color: $color-grey;
  margin-bottom: calc((20px - 8px) / 2);

  span {
    background-color: $color-main;
    display: block;
    height: 100%;
    border-radius: 4px;
  }
}
.percent {
  font-weight: 700;
  font-size: 16px;
  color: $color-main;
  margin-left: 18px;
  width: 45px;
  text-align: right;
  align-self: flex-end;
}
