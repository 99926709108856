@use 'sass:list';
@import 'styles/constants/colors';

@mixin default-transition($property: all) {
  //transition: $property 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  //@if list.length($property) > 1 {
  transition-property: list.join($property, (), $separator: comma);
  //}
}

@mixin default-scrollbar() {
  scrollbar-color: $color-grey;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $color-background;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey;
    border-radius: 3px;
  }
}

@mixin line-clamp($colLine) {
  display: -moz-box;
  -mox-line-clamp: $colLine;
  display: -webkit-box;
  -webkit-line-clamp: $colLine;
  -webkit-box-orient: vertical;
  line-clamp: $colLine;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
