@use 'styles/constants/colors';
@use 'styles/themes';
@use 'styles/mixins';

.root {
  background-color: themes.$integration-bg;

  cursor: pointer;

  box-shadow:
    rgb(0 0 0 / 20%) 0 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px 0px;

  //transition: /*background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,*/ box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms /*border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
  //transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @include mixins.default-transition();

  &:hover {
    box-shadow:
      rgb(0 0 0 / 20%) 0 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px,
      rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
}

.logo {
  height: 50%;
  border-bottom: 2px solid themes.$color-button-icon;
  background: no-repeat center;
}

.descr {
  padding: 10px 18px;
  font-weight: 500;
  line-height: 28px;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  .cost {
    span {
      display: inline-block;
      padding: 0.1em 0.75em;
      border-radius: 2em;
      background: themes.$color-main;
      color: themes.$color-main-inv;
      font-weight: 600;
    }
  }
}
