.cropperContainer {
  position: relative;
  width: 100%;
  height: 400px;
  background: #fff
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAENJREFUWIXt1rENADAMAkE7i7G6PRkZgkhpnh7pREXPjCuIpKReJ2o/CAAAAAAAAACgbUd/YHcjwPcFAAAAAAAAAAAX1csKetRtvGwAAAAASUVORK5CYII=')
    repeat;
}

.dialog {
  :global(.rc-dialog-body) {
    padding-bottom: 24px;
  }
}

.footer {
  text-align: center;
  margin-top: 24px;
}
