@use 'styles/constants/layout';
@use 'styles/mixins';
@use 'styles/themes';

.wrapper {
  z-index: #{layout.$dialog-z-index + 2};

  opacity: 0;
  @include mixins.default-transition(opacity);

  &.isShow {
    opacity: 1;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: #{layout.$dialog-z-index + 1};
  background-color: transparent;
}

.root {
  padding: 10px;
  //box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px,
  //  rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  box-shadow: themes.$popup-shadow;
}

.arrow {
  background: inherit;
  visibility: hidden;
  bottom: -4px;
  width: 8px;
  height: 8px;
  z-index: #{layout.$dialog-z-index + 2};

  &:before {
    content: '';
    position: absolute;
    visibility: visible;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
    background: inherit;
  }
}

.wrapper[data-popper-placement^='left'] .arrow {
  right: -4px;
}

.wrapper[data-popper-placement^='right'] .arrow {
  left: -4px;
}

//.wrapper[data-popper-placement^='top'] .arrow {
//  //bottom: -4px;
//}

.wrapper[data-popper-placement^='bottom'] .arrow {
  top: -4px;
}
