.root {
  display: flex;
  align-items: center;
  button {
    --btn-height: 25px;
    --btn-pad-x: 5px;
    min-width: 25px;

    &:not(:first-child) {
      margin-left: 3px;
    }
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
}
