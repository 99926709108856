@use 'styles/themes';
@import 'styles';

.dialog,
.dialog2 {
  width: 100%;
  max-width: 780px;

  :global(.rc-dialog-body) {
    padding: 110px 84px 20px;
  }
}

.title {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 60px;
  color: $color-background-dark;
  .dialog2 & {
    color: themes.$color-title;
  }
  text-transform: uppercase;
}

.line {
  margin-bottom: 20px;
  font-size: 18px;
  color: $color-grey;
  display: flex;
  align-items: center;

  i {
    margin-right: 8px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.dialog {
  &.green {
    .title span,
    .line i {
      color: $color-green;
    }
  }

  &.red {
    .title span,
    .line i {
      color: $color-red;
    }
  }
}
