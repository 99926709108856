.radioButtons {
  margin-top: 18px;
}

.customerList {
  margin-top: 18px;
  font-size: 16px;
}

.customerListTitle {
  font-weight: 600;
  margin-bottom: 8px;
}
