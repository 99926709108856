@use 'styles/constants/colors';

.root {
  display: inline;
}

.btn {
  --btn-color: colors.$color-line-dark;
  --btn-hover-color: colors.$color-line-dark;
  --btn-active-color: colors.$color-line-dark;
  --btn-disabled-color: colors.$color-grey;
  min-width: 23px;

  &,
  &:disabled,
  &:focus,
  &:active,
  &:hover {
    background: transparent;
  }

  &.left {
    border-radius: 5px 0 0 5px;
    &:disabled {
      border-right-width: 0;
    }
    &:enabled + .right {
      border-left-width: 0;
    }
  }
  &.right {
    border-radius: 0 5px 5px 0;
  }
}
