@use '../../themes';

%fix-dark-dialog {
  :global(.rc-dialog-content) {
    background: themes.$color-background;
    color: themes.$input-def-tx;
  }
  :global(.rc-dialog-close) {
    color: themes.$color-background-inv;
    text-shadow: 0 1px 0 themes.$color-background-inv;
  }
  :global(.rc-dialog-header) {
    background: themes.$color-background;
    color: themes.$color-button-icon;
    border-bottom-color: themes.$input-def-br;
  }
  :global(.rc-dialog-footer) {
    border-top-color: themes.$input-def-br;
  }

  // Должен ли он в тёмной теме наоборот осветлять фон? Вряд ли.
  //:global(.rc-dialog-mask) {
  //  // background-color: ...;
  //}
}
