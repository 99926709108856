@use 'styles/fn';
@use 'styles/components';

.root {
  display: inline-grid;
  @extend %input-box;
  padding-left: 0.75ch;
  padding-right: 0.75ch;
  grid-template-columns: fn.zfr(1) 0 2.5ch;
  align-items: center;
}

.colon {
  display: inline-block;
  pointer-events: none;

  // так с нулевой шириной в эту хрень нельзя вообще ткнуть, отчего фокус
  // попадёт сразу в инпут
  &::before {
    content: ':';
    display: inline-block;
    text-align: left;
    transform: translateX(-50%);
  }
}
.hh,
.mm {
  display: inline-block;
  margin: 0;
  @extend %input-only-text;
  padding-right: 0.25ch;
  align-self: stretch;
  color: inherit;
}
.hh {
  text-align: right;
}
.mm {
  padding-left: 0.25ch;
  text-align: center;
}

._disabled {
  @extend %input-disabled;
  cursor: default;
  pointer-events: none;
  user-select: none;
}

._small {
  @extend %input-size-small;
}
