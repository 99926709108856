@use 'themes';
@use 'vars';

@mixin _apply-vars($theme) {
  @each $name in map-keys($theme) {
    --#{$name}: #{map-get($theme, $name)};
  }
}

%theme-light {
  @include _apply-vars(themes.$theme-light);
}

%theme-dark {
  @include _apply-vars(themes.$theme-dark);
}

$color-main: var(--#{vars.$color-main});
$color-main-hover: var(--#{vars.$color-main-hover});
$color-main-active: var(--#{vars.$color-main-active});
$color-main-focus: var(--#{vars.$color-main-focus});
$color-main-inv: var(--#{vars.$color-main-inv});
$color-background: var(--#{vars.$color-background});
$color-background-inv: var(--#{vars.$color-background-inv});
$color-background-hi: var(--#{vars.$color-background-hi});
$color-background-alt1: var(--#{vars.$color-background-alt1});
$color-background-alt2: var(--#{vars.$color-background-alt2});
$color-background-hover: var(--#{vars.$color-background-hover});
$color-title: var(--#{vars.$color-title});
$color-excretion: var(--#{vars.$color-excretion});
$color-excretion-alt: var(--#{vars.$color-excretion-alt});
$color-button-icon: var(--#{vars.$color-button-icon});
$color-disabled: var(--#{vars.$color-disabled});

$input-def-br: var(--#{vars.$input-def-br});
$input-def-bg: var(--#{vars.$input-def-bg});
$input-def-tx: var(--#{vars.$input-def-tx});
$input-def-pl: var(--#{vars.$input-def-pl});
$input-dis-br: var(--#{vars.$input-dis-br});
$input-dis-bg: var(--#{vars.$input-dis-bg});
$input-dis-tx: var(--#{vars.$input-dis-tx});

$popup-shadow: var(--#{vars.$popup-shadow});

$integration-bg: var(--#{vars.$integration-bg});

$scroll-track: var(--#{vars.$scroll-track});
$scroll-thumb: var(--#{vars.$scroll-thumb});
