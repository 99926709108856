@use 'sass:color';

// REFACT: color-mix() https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-mix

$hover-lighter: 20%;
$hover-lighter-bg: 10%;
$active-lighter: 25%;
$active-lighter-bg: 15%;

@function _hover($color, $lighter: $hover-lighter) {
  @return color.adjust($color, $lightness: -$lighter);
}
@function _hover-dark($color, $lighter: $hover-lighter) {
  @return _hover($color, -$lighter);
}
@function _active($color, $lighter: $active-lighter) {
  @return color.adjust($color, $lightness: -$lighter);
}
@function _active-dark($color, $lighter: $active-lighter) {
  @return _active($color, -$lighter);
}
@function _focus($color, $alpha: -0.4) {
  @return color.adjust($color, $alpha: $alpha);
}

/* main colour */
$color-main: #1890ff;
$color-main-hover: #0d6bc1;
$color-main-active: _active($color-main);
$color-main-focus: _focus($color-main);
/* fon */
$color-background: #f8faff;
/* grey */
$color-grey: #97a5c9;
/* auxiliary accent */
$color-accent: #be56e5;
/* green */
$color-green: #00c938;
/* red */
$color-red: #f73e0f;
/* yellow */
$color-yellow: #fde667;
/* excretion */
$color-excretion: #ebeeff;
$color-background-alt: color-mix(in srgb, #{$color-excretion} 40%, white);

$color-divider: #dee0ea;

//Dark Theme

/* dark theme main colour */
$color-main-dark: #f8faff;
$color-main-dark-hover: #ebeeff;
$color-main-dark-active: _active-dark($color-main-dark);
$color-main-disabled: color.adjust($color-main, $saturation: -50%);
$color-main-dark-focus: _focus($color-main-dark);
/* text colour dark theme */
$color-text-dark: #b7c9f8;
/* line dark theme */
$color-line-dark: #444d78;
/* fon dark theme */
$color-background-dark: #0f1642;
/* excretion dark theme */
$color-excretion-dark: #5966b8;

$color-disabled: #c8c8c8;
$color-disabled-dark: #777;

// tasks

$color-task-opened: #ffa115;
//$color-task-closed: #219653;
$color-task-closed: $color-green;
$color-task-expired: $color-red;
$color-task-deleted: $color-grey;

$color-danger: $color-red;
$color-danger-hover: _hover($color-danger);
$color-danger-active: _active($color-danger);
$color-danger-disabled: color.adjust($color-danger, $saturation: -50%);
$color-danger-focus: _focus($color-danger);

$color-warn: $color-task-opened;
$color-warn-hover: _hover($color-warn);
$color-warn-active: _active($color-warn);
$color-warn-disabled: color.adjust($color-warn, $saturation: -50%);
$color-warn-focus: _focus($color-warn);

// FIXME: Надо цвет чуток поприятнее, а то нынешний $color-green (#00c938)
//  слишком резкий, как по мне (особенно просто зелёный текст на светлом фоне).
$color-success: $color-green;
$color-success-hover: _hover($color-success);
$color-success-active: _active($color-success);
$color-success-disabled: color.adjust($color-success, $saturation: -50%);
$color-success-focus: _focus($color-success);

$color-accent-hover: _hover($color-accent);
$color-accent-active: _active($color-accent);
$color-accent-disabled: color.adjust($color-accent, $saturation: -50%);
$color-accent-focus: _focus($color-accent);

$color-secondary: $color-grey;
$color-secondary-hover: _hover($color-secondary);
$color-secondary-active: _active($color-secondary);
$color-secondary-disabled: color.adjust($color-secondary, $saturation: -50%);
$color-secondary-focus: _focus($color-secondary);
