@use 'styles/themes';
@import 'styles';

$margin: 8px;
//$margin-xs: 12px;
.root {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  .affix {
    flex: 0 0 auto;
  }
  input {
    flex-shrink: 0;
  }
  > .label {
    font-size: inherit;
    line-height: 14px;
    color: themes.$color-title;
  }

  &.end {
    > .label {
      margin-left: $margin;
    }
  }

  &.top {
    flex-direction: column-reverse;
  }
  &.bottom {
    flex-direction: column;
  }

  &.start {
    > .label {
      margin-right: $margin;
    }
    flex-direction: row-reverse;
  }
}
.disabled {
  color: $color-grey;
}
.affix {
  margin: 0 8px 0 0;
  font-size: 24px;
  color: $color-line-dark;
  display: inline-flex;
}

.helper {
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
