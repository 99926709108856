@import 'styles';
.loader {
  margin-top: 25px;
}
.checkboxesCol {
  padding-top: 28px;
  padding-left: 20px;
}
.select {
  margin-bottom: 25px;
}
.checkbox {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.hidden {
  text-decoration: line-through;
}

.compactMode {
  .checkboxesCol {
    padding: 0;
  }
  .select,
  .checkbox {
    margin-bottom: 12px;
  }
}
