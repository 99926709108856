//@import '~styles/constants';
//@import '~styles/mixins';

.IconContainer {
  //@include default-transition;

  display: inline-block;
  vertical-align: middle;

  > svg {
    display: block;
    //fill: currentColor;

    // не сбрасываем встроенные размеры в "em"
    // не уверен насчёт логики И/ИЛИ, но иконок со смешанными типами размеров нет
    &:not([width$='em'][height$='em']) {
      height: 1em;
      width: 1em;
    }
  }
}
