@import 'styles';
.root {
  padding: 20px 54px;
}

.header {
  color: $color-line-dark;
  margin-bottom: 19px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.icon {
  flex: 0 0 auto;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.title {
  flex: 1 1 0;
  font-weight: 700;
  font-size: 24px;
}

.titleEnd {
  flex: 0 0 auto;
}

:global(.dark) {
  .title {
    color: $color-main;
  }
}
