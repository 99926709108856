@import 'styles';
.root {
  position: relative;
  height: 100%;

  .tabs {
    position: absolute;
    bottom: 100%;
    width: 100%;
    padding: 0 var(--content-pad-x);
  }
}
