@import 'styles';
.root {
  background: $color-background;
  border-radius: 10px;
  height: 45px;
  border: 1px solid $color-grey;
  display: inline-flex;
  align-items: center;
  width: 100%;

  &.disabled {
    background-color: #eceef1;
    .affix {
      color: $color-grey;
    }
  }
  &.disabledPointer {
    pointer-events: none;
  }

  input {
    border: none;
    padding: 0;
    height: 100%;
    min-width: 0;
  }

  input:first-child {
    margin-left: 15px;
  }
  input:last-child {
    margin-right: 15px;
  }
}

.affix {
  margin: 0 8px;
  font-size: 24px;
  color: $color-line-dark;
  display: inline-flex;
}

.btnClear {
  height: auto;
  font-size: 24px;
  margin-right: 2px;
}

.sizeSmall {
  height: 38px;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

:global(.dark) {
  .root {
    background: $color-excretion-dark;
    &.disabled {
      background-color: #9197c0;
    }

    .affix {
      color: #ffffff;
    }
  }
}
