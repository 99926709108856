@use 'styles/constants/colors';
@use 'styles/components/button';

.root {
  display: inline-flex;
  align-items: center;

  height: 35px;
  background: colors.$color-excretion-dark;
  color: colors.$color-main-dark;
  padding: 5px 0;
  border-radius: 500px;
}

.icon,
.remove {
  flex: 0 0 auto;
}

.icon,
.label {
  &:first-child {
    margin-left: 15px;
  }
}

.icon {
  font-size: 24px;
  margin-right: 5px;
}

.label {
  flex: 1 1 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding-left: 5px;
  padding-right: 20px;
  &:not(:last-child) {
    padding-right: 10px;
  }
}

.remove {
  flex: 0 0 auto;
  @include button.button-variant(
    $bg: transparent,
    $border: transparent,
    $color: colors.$color-main-dark,
    $hover-bg: transparent,
    $hover-border: transparent,
    $hover-color: colors.$color-main-dark-hover,
    $active-bg: transparent,
    $active-border: transparent,
    $active-color: colors.$color-main-dark-active,
    $disabled-bg: transparent,
    $disabled-border: transparent,
    $disabled-color: colors.$color-disabled,
    $loading-bg: colors.$color-background,
    $focus-color: colors.$color-main-focus
  );
}
