@import 'styles';
.root {
  background-color: $color-background-dark;
  padding: 25px;
  margin-bottom: 12px;
  border-radius: 4px;

  color: #ffffff;
  display: flex;
  align-items: center;
}
.textBlock {
  display: flex;
  flex: 1;
}
.ico {
  font-size: 40px;
}
.text {
  font-weight: 50;
  font-size: 20px;
  flex: 1;
  margin: 0 25px;
}
