@import 'styles';
.root {
  //max-width: 500px;
}
.item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  button {
    margin-left: 12px;
    height: auto;
  }

  &.error {
    input {
      border-color: $color-red;
    }
  }
}

.hide {
  visibility: hidden;
}

.btnAdd {
  color: $color-main;
}
