@use 'styles/themes';
@import 'styles';

* {
  box-sizing: border-box;
}

:focus,
:focus-visible {
  outline: none;
}

html,
body,
#root {
  // https://developer.chrome.com/blog/url-bar-resizing/
  width: 100%;
  height: 100%;
  overflow: hidden;
  // старый `overflow: hidden` всё равно позволяет элементам скроллиться
  // программно, отчего `whateverElement.scrollIntoView()` может приводить
  // к скроллингу не того контейнера, который имелся ввиду.
  overflow: clip;
}

#root {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: min-content minmax(0, 1fr);

  > .tech-panel {
  }
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: $color-line-dark;
  color: themes.$input-def-tx;

  //-webkit-backface-visibility: hidden;
}

h1,
h2 {
  //color: $color-background-dark;
  color: themes.$color-background-inv;
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 35px;
  margin-bottom: 30px;
}
h2 {
  font-size: 30px;
  margin-bottom: 21px;
}

button {
  font-family: 'Inter', sans-serif;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

input,
textarea {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 18px;
  height: 45px;
  border-radius: $input-border-radius;
  border: 1px solid themes.$input-def-br;
  background: themes.$input-def-bg;
  padding: 0 15px;
  outline: none;
  color: themes.$input-def-tx;
  width: 100%;

  &::placeholder {
    color: themes.$input-def-pl;
  }
  &:disabled {
    background-color: themes.$input-dis-bg;
    color: themes.$input-dis-tx;
    border-color: themes.$input-dis-br;
  }
}

input {
  &[type='checkbox'] {
    width: 14px;
    height: 14px;
    margin: 0;
    border-radius: 3px;
    border: 1px solid themes.$input-def-tx;

    &:checked {
      background-color: $color-main;
    }
  }
}
input {
  &[type='radio'] {
    width: 14px;
    height: 14px;
    margin: 0;
  }
}
textarea {
  height: 128px;
  padding-top: 11px;
  padding-bottom: 11px;
  &::placeholder {
    font-size: 16px;
  }
}

a {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: $color-main;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
