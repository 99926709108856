@import 'styles';

.root {
  width: 800px;
  :global(.rc-dialog-body) {
    padding: 30px;
  }
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
}

.blockTitle {
  font-weight: bold;
  margin-bottom: 12px;
  background-color: $color-excretion;
  padding: 8px;
  border-radius: 4px;
}

.content {
  display: flex;
}

.left,
.right {
  flex: 1;
}
.left {
  padding-right: 12px;
}
.right {
  padding-left: 12px;
}

.footer {
  margin-top: 24px;
  text-align: right;
  button + button {
    margin-left: 12px;
  }
}
