@import 'styles';

.button {
  background-color: $color-main;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 12px;
  display: inline-flex;

  .label {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .qty {
    margin-left: 6px;
    font-weight: bold;
  }

  &.empty {
    background-color: $color-grey;
  }
}

.content {
  max-width: 350px;
}

.header {
  margin-bottom: 12px;
}

.info {
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    height: auto;
    font-size: 12px;
  }
}

.items {
  max-height: 300px;
  overflow-y: auto;
}

.item {
  display: flex;
  margin-bottom: 8px;
  span span {
    color: $color-main;
  }
}

.blue {
  color: $color-main;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  border-top: 1px solid $color-grey;
  padding-top: 4px;

  button {
    height: auto;
    font-size: 14px;
    padding: 4px;
  }
}
