.button {
}

.popup {
  padding: 0;
}
.popupContent {
  padding: 10px;
}
.yearRow {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: min-content min-content 1fr min-content min-content;
  align-items: center;
}
.year {
  text-align: center;
}
.months {
  margin-top: 5px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
}
