.root {
  height: 33px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    height: 78%;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      height: 100%;
    }

    &:not(.active):hover {
      //todo add transition
      //height: 85%;
      transform: scale(1.2);
    }
  }
}
