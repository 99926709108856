@use '../../themes';

%panel-shadow {
  box-shadow: 0 1px 13px rgba(82, 54, 255, 0.2);
}

%panel-box {
  @extend %panel-shadow;
  background: themes.$color-background;
  border-radius: 15px;
}
