@use 'styles/constants/colors';
@use 'styles/components';
@use 'styles/themes';

.root {
  @extend %themed-scrollbars;

  position: relative;
  overflow: auto;
  background: themes.$color-background-hi;
  color: themes.$color-title;
  //border: colors.$color-grey solid 1px;

  --grid-border-fixed-inner-size: 1px;
  --grid-border-fixed-outer-size: 2.5px;
  --grid-border-data-size: 1px;
  --grid-border-fixed-outer-add-size: calc(
    var(--grid-border-fixed-outer-size) - var(--grid-border-fixed-inner-size)
  );
  // субпиксельные эффекты мешают жить - возникают фантомные дыры из-за того, что
  // логические пикселы не равны физическим пикселам
  --grid-border-fixed-outer-display-size: calc(var(--grid-border-fixed-outer-add-size) + 0.5px);

  --grid-border-color: #{colors.$color-grey};
  --grid-border-fixed-inner-color: var(--grid-border-color);
  //--grid-border-fixed-inner-color: green; // debug
  --grid-border-fixed-outer-color: var(--grid-border-color);
  //--grid-border-fixed-outer-color: red; // debug
  --grid-border-data-color: var(--grid-border-color);
  //--grid-border-data-color: blue; // debug

  --grid-cell-bg: #{themes.$color-background-hi};

  // переопределяется при рендере в style=""
  --grid-fixed-cols-count: 0;
  --grid-fixed-cols: 0px;
  --grid-fixed-w: 0px;
  --grid-fixed-rows-count: 0;
  --grid-fixed-rows: 0px;
  --grid-fixed-h: 0px;
  --grid-data-cols-count: 0;
  --grid-data-rows-count: 0;
  --grid-data-w: 0px;
  --grid-data-h: 0px;

  // полные размеры главных частей
  --grid-fixed-width: calc(var(--grid-fixed-w) + var(--grid-border-fixed-outer-add-size));
  --grid-fixed-height: calc(var(--grid-fixed-h) + var(--grid-border-fixed-outer-add-size));
  --grid-data-width: calc(var(--grid-data-w) * var(--grid-data-cols-count));
  --grid-data-height: calc(var(--grid-data-h) * var(--grid-data-rows-count));

  --grid-scroll-shadow-color: #{colors.$color-grey};
  --grid-scroll-shadow: 0 0 5px 2px var(--grid-scroll-shadow-color);
  &._xScrolled > .content::before,
  &._yScrolled > .content::after {
    content: ' ';
    display: block;
    position: sticky;
    z-index: 1;
    box-shadow: var(--grid-scroll-shadow);
  }
  &._xScrolled {
    > .content::before {
      grid-area: top;
      grid-row-end: data-end;
      left: var(--grid-fixed-width);
      width: 0;
      height: calc(var(--grid-fixed-height) + var(--grid-data-height));
    }
  }
  &._yScrolled {
    > .content::after {
      grid-area: left;
      grid-column-end: data-end;
      top: var(--grid-fixed-height);
      width: calc(var(--grid-fixed-width) + var(--grid-data-width));
      height: 0;
    }
  }
}

.content {
  display: grid;
  width: calc(var(--grid-fixed-width) + var(--grid-data-width));
  height: calc(var(--grid-fixed-height) + var(--grid-data-height));
  grid-template-areas:
    'corner top'
    'left   data';
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
}

.corner,
.top,
.left,
.data {
  border-style: solid;
  border-width: 0;
  display: grid;
  // также для компенсации дыр между границами от субпиксельных вычислений
  background: var(--grid-cell-bg);
}

.corner,
.top,
.left {
  position: sticky;
  border-color: var(--grid-border-fixed-outer-color);
  --grid-cell-border-size: var(--grid-border-fixed-inner-size);
  --grid-cell-border-color: var(--grid-border-fixed-inner-color);
}

.corner,
.left {
  left: 0;
  width: var(--grid-fixed-width);
  border-right-width: var(--grid-border-fixed-outer-display-size);
  grid-template-columns: var(--grid-fixed-cols);
}

.corner,
.top {
  top: 0;
  height: var(--grid-fixed-height);
  border-bottom-width: var(--grid-border-fixed-outer-display-size);
  grid-template-rows: var(--grid-fixed-rows);
}

.left,
.data {
  border-bottom-width: var(--grid-border-data-size);
  height: var(--grid-data-height);
  grid-template-rows: repeat(var(--grid-data-rows-count), var(--grid-data-h));
}

.top,
.data {
  border-right-width: var(--grid-border-data-size);
  width: var(--grid-data-width);
  grid-template-columns: repeat(var(--grid-data-cols-count), var(--grid-data-w));
}

.corner {
  grid-area: corner;
  // он здесь вообще нужен для того, чтобы покрывать угол, т.к. при скролле там
  // "невидимые" части top и left гуляют
  z-index: 3;
}
.top {
  grid-area: top;
  z-index: 2;
}

.left {
  grid-area: left;
  z-index: 2;
}

.data {
  grid-area: data;
  border-color: var(--grid-border-data-color);
  --grid-cell-border-size: var(--grid-border-data-size);
  --grid-cell-border-color: var(--grid-border-data-color);
}
