@use '../input/config' as i;

$radius: i.$radius;
$border: i.$border;
$pad-x: i.$pad-x;
$pad-y: i.$pad-y;
$font: 18px; //i.$font;
$font-weight: 700;
$lh: i.$lh;

$focus-width: i.$focus-width;
$disabled-opacity: 1; //0.65;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$size-extras-x: calc($pad-x * 2 + $border * 2);

$full-height-oneline: calc(/*$font * */ $lh + 2 * $pad-y + 2 * $border);

$default-height: 48px; // i.$default-height;
$default-width: 133px;
$default-icon: 24px;
$default-icon-space: 8px;

$small-radius: i.$small-radius;
$small-height: 32px; //i.$small-height;
$small-width: $default-width;
$small-icon: 16px;
$small-icon-space: 8px;
$small-font: i.$small-font;
$small-font-weight: normal;
$small-lh: i.$small-lh;

$large-radius: $radius;
$large-height: 67px;
$large-width: 270px;
$large-icon: $default-icon;
$large-icon-space: $default-icon-space;
$large-font: 24px;
$large-font-weight: 700;
$large-lh: $lh;

$largeX-radius: $radius;
$largeX-height: 80px;
$largeX-width: 133px;
$largeX-icon: $default-icon;
$largeX-icon-space: $default-icon-space;
$largeX-font: 24px;
$largeX-font-weight: 700;
$largeX-lh: $largeX-font;
