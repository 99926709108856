@use 'styles/constants/colors';
@use 'styles/fn';
@use 'styles/themes';

.root {
  position: sticky;
  z-index: 99999;
  box-shadow: 0 0 5px #888;
}

.event {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: fn.zfr(1) min-content;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  background-color: color-mix(in srgb, #{colors.$color-warn} 60%, #{themes.$color-background});
  color: themes.$color-title;

  .info {
    text-align: center;

    .icon {
      font-size: 20px;
    }

    button,
    a {
      font-size: 100%;
      min-height: 0;
    }
  }
  .remove {
  }
}
