@import 'styles';

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 500;
  color: $color-background-dark;
}
.logo {
  height: 60px;
  margin-bottom: 80px;
}
.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 6px;
}
.descr {
  font-size: 20px;
  margin-bottom: 36px;
}
.btn {
  min-width: 485px;

  &:not(:last-child) {
    margin-bottom: 19px;
  }
}
